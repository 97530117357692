export const environment = {
    production: true,
    hotjar: {
        enabled: true,
        id: 5028808
    },
    baseUrl: 'https://costagridtestentity.costa.express',
    apiUrl: {
        entity: 'https://costagridtestentity.costa.express',
        machine: 'https://costagridtestmachine.costa.express',
        user: 'https://costagridtestuser.costa.express',
        salesProcessor: 'https://costagridtestsales.costa.express',
        event: 'https://costagridtestnotification.costa.express',
        soxAuditLog: 'https://costagridtestaudit.costa.express',
        alarmProcessor: 'https://costagridtestalarm.costa.express',
        signalR: 'https://costagridtestsignalr.costa.express',
        remoteUpdate: 'https://remote-update-tasks-management-uat.azurewebsites.net',
        azureAppConfig: 'Endpoint=https://grid-ui-uat.azconfig.io;Id=KNj4;Secret=m86nuT9yUbIfjNtRrCyfaTVma7N7ASIQVrYhyVaeeNE=',
        remoteUpdatePackage: 'https://remote-update-packages-management-uat.azurewebsites.net'
    },
    cookieName: 'CE_',
    env: 'uat',
    version: '2.35.2',
    domainName: "costagridtest.costa.express",
    siteKeyCaptcha: '6LfDn5ocAAAAAKkog37xYn2bqXtTEHn-r1v72sdy',
    msal: {
        authority: "https://login.microsoftonline.com/6511efd3-03fb-449b-9a07-00ccbcc060ae/",
        clientId: "51b8c732-20e3-40dd-8cb2-9422e4ec866e",
        redirectUrl: "https://costagridtest.costa.express/callback",
        postLogoutRedirectUri: "https://costagridtest.costa.express/",
        loginscope: "openid email profile",
        apiscope: "api://39c116bd-493a-4c3c-9042-52fcc5af655f/access_as_user",
        costaExpressUrl: "https://idamtest.costa.express"
    }
};
